.description-section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.infoWrapper {
    transition: all 1s ease;
    transform: translateX(-150%);
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    bottom: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: space-between;
    height: calc(50% + 100px);
}
.infoWrapper.active {
    transform: translateY(0);
    opacity: 1;
}
.infoWrapper_image {
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    background-size: contain;
    max-width: 200px;
}
.infoWrapper_text {
    margin-bottom: 30px;
    color: #5A505B;
    text-align: center;
    max-width: 80%;
}

@media (max-width: 991px) {
    .description-section {
        width: 100%;
        position: relative;
        overflow: visible;
        height: 120px;
        flex-shrink: 0;
        padding: 0;
    }
    .infoWrapper {
        position: absolute;
        width: 100%;
        flex-shrink: 0;
        top: 0;
        left: 0;
        bottom: auto;
        height: auto;
    }
    .infoWrapper_image {
        flex-shrink: 0;
        height: 80px;
        background-size: contain;
    }
    .infoWrapper_text {
        max-width: 60%;
        margin-bottom: 0;
        margin-top: 20px;
    }
}