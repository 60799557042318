.menu-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    left: -100%;
    transition: left .5s ease-out;
}

.menu-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 16px;
    background-color: #F1F1F1;
}

.menu-wrapper.open {
    left: 0;
}

.menu-links-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.menu-link-item {
    color: #5A505B;
    text-align: center;
    font-family: 'TacticSans-Reg', sans-serif;
    font-size: 24px;
    letter-spacing: -1.92px;
    margin-bottom: 20px;
}

.menu-link-item:hover {
    color: #fff;
}

.menu_logo_image {
    opacity: .03;
    position: absolute;
    top: calc(50% - 317px);
    left: calc(50% - 327px);
}

.closeMenu {
    position: absolute;
    right: 20px;
    top: 20px;
}

.menu-content {
    overflow: hidden;
}

.menu_logo_image {
    z-index: 0;
}

.menu-links-wrapper {
    z-index: 1;
}