.addToCartBtn {
    color: #5A505B;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -1.6px;
    border-radius: 6px;
    border: 1px solid #5E4545;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 10px);
    margin-left: 20px;
    transition: all .3s ease;
    cursor: pointer;
    font-family: 'Aeroport', sans-serif;
}

.addToCartBtn:hover {
    background-color: #5E4545;
    color: #fff;
}