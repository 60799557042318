.cart_page_wrapper-items {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    height: calc(100vh - 62px);
    align-items: center;
    overflow-x: hidden;
    flex-wrap: wrap;
}
.cart_item {
    width: 252px;
    margin: 0 15px;
    flex-shrink: 0;
    text-align: center;
    padding-bottom: 26px;
    margin-bottom: 26px;
    border-bottom: 1px solid #5A505B;
}
.cart_item_image img {
    width: 48px;
    height: 82px;
    object-fit: cover;
    display: block;
    margin: auto;
}
.cart_item_delete {
    font-family: 'Aeroport', sans-serif;
    font-size: 20px;
    line-height: 20px;
    width: 21px;
    height: 21px;
    border: 1px solid #5E4545;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 26px;
}
.cart_item_name {
    color: #5A505B;
    font-family: 'TacticSans-Reg', sans-serif;
    font-size: 24px;
    margin-bottom: 0;
    height: 68px;
    text-transform: uppercase;
}
.bottom-cart {
    width: 100%;
    margin-top: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.cart_checkout {
    width: 160px;
    margin-top: 25px;
    margin: 0;
}



@media (max-width: 991px) {
    .cart_page_wrapper-items {
        height: 100%;
        min-height: calc(100vh - 62px);
    }
    .bottom-cart {
        margin-bottom: 20px;
    }
}