.slider-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.slider {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 1.3s ease;
}

.slide {
    flex: 0 0 100vh;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    transition: opacity 0.9s ease;
    opacity: 0.3;
}

.slide.active {
    opacity: 1;
}

@media (max-width: 991px) {
    .slider-container {
        width: 180px;
        overflow: visible;
    }
    .slider-container img {
        max-height: 100%;
        max-width: 100%;
    }
    .slider-container .swiper {
        overflow: visible;
    }
    .slider-container .swiper-slide {
        transition: all .3s ease;
    }
    .slider-container .swiper-slide:not(.swiper-slide-active) {
        filter: blur(5px);
    }
    .slider-container .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-prev):not(.swiper-slide-next) {
        opacity: 0;
    }

}