.popup {
    position: fixed;
    top: 0;
    transform: translateY(-50%);
    left: 0;
    transform: translateX(-50%);
    background: #fff;
    padding: 10px 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.5s ease;
    z-index: -1;
}
  
.popup.show {
    z-index: 999;
    opacity: 1;
    transform: translateX(0) translateY(0);
}



