.about-text_wrapper {
    max-width: 900px;
    text-align: center;

}
.about-text_wrapper p {
    margin: 0;
}
.about-text_wrapper .topText {
    color: #CACACA;
    font-family: 'TacticSans-Reg', sans-serif;
    font-size: 36px;
    line-height: 92.2%;
    letter-spacing: -1.96px;
    margin-bottom: 10px;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
}
.about-text_wrapper .bottomText {
    color: #5A505B;
    text-align: right;
    font-size: 24px;
    line-height: 92.2%;
    letter-spacing: -1.92px;
    text-transform: lowercase;
    color: #fff;
    max-width: 80%;
    display: block;
    margin-left: auto;
}
.socialLinks a {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.socialLinksYoutube {
    position: relative;
    top: 5px;
    left: 2px;
}

.socialLinks {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 15px;
    justify-content: flex-end;
}
.socialLinks a {
    margin-right: 5px;
}

@media (max-width: 991px) {
    .about-text_wrapper .topText {
        text-align: center;
        margin-top: 80px;
        font-size: 29px;
    }
    .about-text_wrapper .bottomText {
        width: 50%;
    }
    .socialLinks {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 15px;
    }
    .socialLinks a {
        margin-bottom: 5px;
        margin-right: 0;
    }
}
