.brands-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.brands-section .item-wrapper {
    height: 150px;
    overflow: hidden;
    margin-top: auto;
}

.brands-section .item {
    color: #5A505B;
    font-family: 'TacticSans-Reg', sans-serif;
    font-size: 24px;
    letter-spacing: -1.68px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .5s ease;
}

.brands-section .item {
    filter: blur(3px);
    transform: scale(0.8);
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brands-section .item.swiper-slide-active {
    filter: none;
    transform: scale(1);
}
.btn-wrapper {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 235px;
}

@media (max-width: 991px) {
    .brands-section {
        width: 100%;
    }
    .brands-section .swiper {
        width: 100%;
        margin-bottom: 30px;
    }
    .brands-section .item-wrapper {
        height: auto;
        overflow: visible;
    }
    .brands-section .item {
        font-size: 14px;
        padding: 0;
    }
    .brands-section .item.swiper-slide-active {
        transform: scale(1.4);
    }
}
