.priceWrapper {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.08em;
    text-align: center;
    margin-bottom: 20px;
    color: #5A505B;
}
.priceWrapper .oldPrice {
    display: block;
    margin-bottom: 10px;
    font-size: 17px;
    text-decoration: line-through;
}
@media (max-width: 991px) {
    .priceWrapper {
        font-size: 21px;
        margin-bottom: 15px;
    }
    .priceWrapper .oldPrice {
        margin-bottom: 3px;
        font-size: 14px;
    }
}