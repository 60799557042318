.info-page {
    padding: 0 50px 50px;
    height: 100vh;
}
.info-page-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #5A505B;
    padding-top: 30px;
}
.info-page-navigation button {
    font-size: 20px;
    letter-spacing: -1.6px;
    text-transform: lowercase;
    border-radius: 6px;
    border: 1px solid #5E4545;
    padding: 6px 24px;
    margin-right: 15px;
    opacity: .4;
    transition: all .4s ease;
}
.info-page-navigation button.active {
    opacity: 1;
}
.info-page-navigation button:not(.active):hover {
    background-color: #5E4545;
    color: #fff;
    opacity: 1;
}
.info-page-content {
    width: 70%;
    margin: auto;
    overflow: hidden;
    height: 70vh;
    position: relative;
}
.info-page-content > div {
    width: 100%;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    height: 100%;
    overflow-y: scroll;
    transition: all .5s ease;
}
.privacyText {
    left: 0;
}
.termsText {
    left: 100%;
}

@media (max-width: 991px) {  
    .info-page {
        padding: 0;
    }
    .info-page-navigation {
        padding: 15px;
        flex-direction: column;
        border-bottom: none;
        margin-bottom: 0;
    }
    .info-page-navigation button {
        margin: 0;
        margin-bottom: 15px;
        width: 300px;
        color: #000;
        opacity: 1;
    }
    .info-page-navigation button.active {
        opacity: .4;
    }
    .info-page-content {
        width: calc(100% - 20px);
        height: calc(100vh - 186px);
    }
    
}