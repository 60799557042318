.checkout_form > div:first-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.checkout_form_item {
    width: 100%;
    margin-bottom: 19px;
    position: relative;
}

.checkout_form_item span {
    font-family: 'Aeroport', sans-serif;
    font-size: 16px;
    color: #5A505B;
    display: block;
    margin-bottom: 12px;
    line-height: 1;
}

.checkout_form_item .error_field {
    position: absolute;
    right: 0;
    color: red;
    top: 0;
}

.checkout_form_item input {
    padding: 18px;
    border: 1px solid #5A505B;
    border-radius: 10px;
    font-family: 'Aeroport', sans-serif;
    font-size: 16px;
    color: #5A505B;
    line-height: 1;
    display: block;
    width: 100%;
    background: transparent;
}

.firstname_field,
.lastname_field,
.country_field,
.city_field,
.address_field,
.zipcode_field,
.email_field,
.phone_field {
    width: calc(50% - 30px);
}

.country_field > div > div:first-of-type {
    border: 1px solid #5A505B;
    border-radius: 10px;
    font-family: 'Aeroport', sans-serif;
    font-size: 16px;
    color: #5A505B;
    line-height: 1;
    width: 100%;
    background: transparent;
}

.country_field > div > div > div:first-child{
    padding: 14px 0 14px 18px;
}

.phone_field  .react-tel-input .form-control {
    height: auto;
    border: 1px solid #5A505B;
    border-radius: 10px;
    font-family: 'Aeroport', sans-serif;
    font-size: 16px;
    color: #5A505B;
    line-height: 1;
    width: 100%;
    background: transparent;
}

.phone_field .react-tel-input .selected-flag:hover, .phone_field .react-tel-input .selected-flag:focus {
    background-color: transparent;
}

.react-tel-input .flag-dropdown {
    border: 1px solid #5A505B;
    border-radius: 10px;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn_wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-top: 1px solid #E2E8F0;
    padding-top: 19px;
}
.btn_wrapper > button {
    height: 60px;
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #5A505B;
    border-radius: 10px;
    font-family: 'Aeroport', sans-serif;
    font-size: 16px;
    color: #5A505B;
    line-height: 1;
    box-shadow: 0px 10px 8px 0px rgba(0, 0, 0, 0.05);
}

.phone_field .react-tel-input .country-list .country {
    display: flex;
    align-items: center;
}
.phone_field .react-tel-input .country-list .country > * {
    padding: 0;
    margin: 0;
    margin-right: 10px;
}
.btn_wrapper > div {
    width: 100%;
}
@media (max-width: 991px) {
    .checkout_content {
        margin-top: 0;
    }
    .checkout_contetn_wrapper {
        flex-direction: column-reverse;
    }
    .checkout_content_left {
        width: 100%;
    }
    .checkout_content_right {
        width: 100%;
    }
    .checkout_content_right .cart_item {
        margin: 0;
    }
    .checkout_content_right .cart_item_price {
        width: 30%;
    }
    .checkout_content_left_tabs_nav {
        margin-bottom: 20px;
        margin-top: 30px;
    }
    .firstname_field, .lastname_field, .country_field, .city_field, .address_field, .zipcode_field, .email_field, .phone_field {
        width: calc(50% - 5px);
    }
    .checkout_form_item input {
        padding: 10px;
    }
    .country_field > div > div > div:first-child {
        padding: 7px 0 7px 4px;
    }
    .country_field > div > div > div:last-child {
        padding: 2px;
    }
    .checkout_form_item input {
        font-size: 14px;
    }
    .country_field > div > div {
        font-size: 14px;
    }
    .phone_field .react-tel-input .form-control {
        font-size: 14px;
    }
    .cart_item_name {
        font-size: 18px;
    }
    .btn_wrapper > button {
        height: 40px;
        width: 140px;
    }
    .btn_wrapper {
        justify-content: center;
    }
}
.payByXendit {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #5A505B;
    border-radius: 10px;
    font-family: 'Aeroport', sans-serif;
    font-size: 16px;
    color: #5A505B;
    line-height: 1;
    box-shadow: 0px 10px 8px 0px rgba(0, 0, 0, 0.05);
margin-bottom: 20px;
}
