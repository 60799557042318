.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 20px 16px;
    width: 40%;
}
.menu_btn {
    margin-right: 20px;
}
.LogoText {
    font-family: 'TacticSans-Med', sans-serif;
    color: #5A505B;
    letter-spacing: -.3px
}
.menu_nav-wrapper > * {
    font-family: 'TacticSans-Reg', sans-serif;
}
.ChangeCategories button {
    font-family: 'TacticSans-Reg', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: #5A505B;
    border-right: 1.5px solid #5A505B;
    letter-spacing: -1px;
    padding: 0 4px;
}
.ChangeCategories button:last-child {
    border-right: none;
}
.menu_nav-wrapper a {
    position: relative;
}
.itemCounter {
    position: absolute;
    background: #5A505B;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -15px;
    right: -13px;
    font-size: 11px;
    border-radius: 50%;
    width: 18px;
    padding: 0;
    height: 18px;
}

@media (max-width: 991px) {
    .menu {
        padding: 15px;
        width: 100%;
    }
}