.about-page_content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.about-page {
    height: 100vh;
    opacity: 0;
    transition: opacity .8s ease;
}
.about-page.hidden-page {
    opacity: 1;
}
.about-page {
    background-image: url(/public/img/bg_about.jpg);
    background-size: cover;
    background-position: center 62px;
    background-repeat: no-repeat;
}

@media (max-width: 991px) {
    .about-page_content {
        height: auto;
        display: block;
    }
    .about-page {
        background-position: center 52px;
    }
    .about-text_wrapper {
        padding: 0 15px;
    }
}