.gallery-page {
    height: 100vh;
    opacity: 0;
    transition: opacity .8s ease;
}
.gallery-page.hidden-page {
    opacity: 1;
}
.itemVideoNavigation {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.gallery-page_content {
    padding: 0 50px 50px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.videoNavigation {
   width: 100%;
   padding-top: 15px;
   border-top: 1px solid #000;
}
.swiperNavVideo {
    height: 100%;
}
.itemVideoNavigation {
    cursor: pointer;
}