
@font-face {
    font-family: 'TacticSans-Reg';
    src: url('../public/fonts/tactic-sans/TacticSans-Reg.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'TacticSansExtExd-Med';
    src: url('../public/fonts/tactic-sans/TacticSansExtExd-Med.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'TacticSans-Med';
    src: url('../public/fonts/tactic-sans/TacticSans-Med.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Aeroport';
    src: url('../public/fonts/aeroport/Aeroport.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: #F5F5F5;
    font-family: 'Aeroport', sans-serif;
    font-size: 16px;
}

body * {
    box-sizing: border-box;
}
button {
    border: none;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
    font-size: inherit;
}
a {
    text-decoration: none;
    color: #5A505B;
}
.btn-link {
    text-decoration: none;
    color: #5A505B;
    background: linear-gradient(0deg, #5A505B, #5A505B) no-repeat right bottom / 0 var(--bg-h);
    transition: background-size 350ms;
    --bg-h: 100%;
    transition: all .3s ease;
}
.btn-link:hover, .btn-link:focus-visible {
    background-size: 100% var(--bg-h);
    background-position-x: left;
    color: #fff;
}


a {
    background: linear-gradient(0deg, #5A505B, #5A505B) no-repeat right bottom / 0 var(--bg-h);
    transition: background-size 350ms;
    --bg-h: 100%;
    transition: all .3s ease;
}
a:where(:hover, :focus-visible) {
    background-size: 100% var(--bg-h);
    background-position-x: left;
    color: #fff;
}

@media (max-width: 991px) {
    body {
        overflow-y: scroll;
        font-size: 14px;
        overflow-x: hidden;
    }
}
