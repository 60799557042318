.loader {
	background-color: #F5F5F5;
	height: 100vh;
	align-items: center;
	display: flex;
	justify-content: center;
	overflow: hidden;
	position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 10;
	transition: opacity 1.5s;
}
.loader-link {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	color: #5A505B;
	text-decoration: none;
	font-family: 'TacticSansExtExd-Med', sans-serif;
	line-height: 1;
	filter: brightness(1.1);
	font-size: 22px;
	text-transform: lowercase;
	transition: opacity 1.5s;
	cursor: pointer;
	border: none;
	border-radius: 0;
	background: transparent;
	letter-spacing: -1px;
}
.loader.opacity {
	opacity: 0;
}
.line-1.line.close {
	transform: translateX(-100%);
}
.line-1.line.close.line div div {
	transform: translateX(-105%);
}
.line-3.line.close {
	transform: translateY(-100%);
}
.line-3.line.close.line div div {
	transform: translateY(-105%);
}
.line-2.line.close {
	transform: translateX(100%);
}
.line-2.line.close.line div div {
	transform: translateX(105%);
}


.loader-link__img {
	padding-bottom: 10px;
	width: 112px;
	height: auto;
	flex-shrink: 0;
}
.line-1,
.line-2{
  top: calc(50% - .5px);
  width: calc(50% - 100px);
  height: 1px;
}
.line-1 {
	left: 0;
}
.line-2 {
	right: 0;
}
.line-3,
.line-4 {
	left: calc(50% - .5px);
	width: 1px;
	height: 100vh;
	z-index: 11;
}
.line-3 {
	top: calc(-50vh - 130px);
}
.line-4 {
	bottom: calc(-50vh - 130px);
}
.line {
  position: absolute;
  transition: 1.5s ease;
  background-color: #5A505B;
}
.line div{
	position: relative;
	width: 100%;
	height: 100%;
}
.line div div {
	position: absolute;
	transition: 1.5s ease;
	background-color: #5A505B;
	width: 5px;
	height: 5px;
	border-radius: 50%;
}
.line-1 div div {
	left: 100%;
}
.line-4 div div {
	bottom: 100%;
}
.line-4 div div,
.line-3 div div {
	left: -2px;
}
.line-2 div div,
.line-1 div div {
	top: -2px;
}
.line-3 div div {
    top: 100%;
}
.line-2 div div {
	right: 100%;
}
.block__loader-link {
	transition: opacity .4s ease;
}
.block__loader-link.hide {
	opacity: 0;
}



/* @media (max-width: 1300px)  {
	.line-3,
	.line-4 {
		height: 34%;
	}
	.line-1,
	.line-2 {
		width: 40%;
	}

}  */

@media (max-width: 991px)  {
	.line-1, .line-2 {
		width: calc(50% - 50px);
	}
	.line-3 {
		top: 0;
	}
	.line-4 {
		bottom: 0;
	}
	.line-3, .line-4 {
		height: calc(var(--vh, 1vh) * 50 - 90px);
	}
	.loader {
		height: calc(var(--vh, 1vh) * 100);
	}
} 

