.chose_size-wrapper {
    color: #5A505B;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -1.6px;
    position: relative;
    transition: all .3s ease;
    overflow: hidden;
    width: calc(50% - 10px);
    z-index: 2;
}

.chose_size-wrapper.open {
    overflow: visible;

}

.chosen_size {
    position: relative;
    padding: 6px 45px 6px 15px;
    cursor: pointer;
    border: 1px solid #5E4545;
    border-radius: 6px;
    width: 100%;
    height: 32px;
    background: #F5F5F5;
    transition: border-color .2s ease;
}

.chosen_size.alert {
    border-color: red;
    color: red;
}

.chose_size-wrapper.open .chosen_size  {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.chosen_size svg {
    position: absolute;
    right: 10px;
    top: 13px;
    transition: all .3s ease;
}

.chose_size-wrapper.open svg {
    transform: rotate(180deg);
}

.choosen_size-options {
    position: absolute;
    width: 100%;
    border: 1px solid #5E4545;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    left: 0;
    top: 100%;
    opacity: 0;
    border-top: none;
    background: #F5F5F5;
}
.choosen_size-options_item {
    padding: 5px 10px;
    cursor: pointer;
    transition: all .3s ease;
}

.chose_size-wrapper.open .choosen_size-options  {
    opacity: 1;
}

.choosen_size-options_item:hover {
    background-color: #5E4545;
    color: #fff;
}