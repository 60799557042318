.home-page {
  height: 100vh;
  display: flex;
}

.image-section {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.description-section {
  width: 35%;
  padding: 20px;
}

.brands-section {
  width: 25%;
  padding: 20px;
}

.home-page .menu {
  width: 100%;
}

.home-page .ChangeCategories {
  display: flex;
}

@media (max-width: 991px) {
  .image-section {
    width: 100%;
  }
  .home-page .menu {
    padding: 15px;
  }
  .home-page {
		flex-direction: column;
		height: auto;
	}
  .home-page {
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    
  }
  .home-page.loader_closed {
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: calc(var(--vh, 1vh) * 100);
    justify-content: space-between;
  }

}