.checkout_content {
    padding: 20px;
    margin-top: 75px;
}
body.checkout {
	overflow: auto
}
.checkout_contetn_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkout_content_left {
    width: 40%;
}
.checkout_content_right {
    width: 40%;
}
.checkout_content_left_tabs_nav {
    display: flex;
    align-items: center;
    position: relative;
    font-family: 'TacticSans-Reg', sans-serif;
    font-size: 20px;
    color: #828282;
    margin-bottom: 50px;
}

.checkout_content_left_tabs_nav .active {
    color: #5A505B;
}

.checkout_content_left_tabs_nav svg {
    margin-left: 10px;
    margin-right: 10px;
}

.checkout_content_left_tabs_nav .separator {
    width: 24px;
    height: 1px;
    background-color: #5A505B;
    display: block;
}

.checkout_content_left_tabs_nav .separator.first{
    margin-left: 5px;
}

.checkout_content_left_tabs_nav .separator.last{
    margin-right: 5px;
}

.checkout_title_form {
    font-family: 'TacticSans-Reg', sans-serif;
    font-size: 20px;
    color: #5A505B;
    margin-bottom: 14px;
    font-weight: 400;
    margin-top: 0;
    display: block;
    width: 100%;
}

.checkout_content_right .cart_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: none;
}

.checkout_content_right .cart_item_name {
    width: 40%;
    height: auto;
}

.checkout_content_right .cart_item_image {
    width: 20%;
}
.checkout_content_right .cart_item_price {
    width: 15%;
    margin-bottom: 0;
}

.bottom-right_checkout {
    padding-top: 30px;
    border-top: 1px solid #5A505B;
    width: 100%;
}

.bottom-right_checkout .cart-amount {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #5A505B;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.bottom-right_checkout .cart-amount  .total {
    font-size: 24px;
}

@media (max-width: 991px) {
    .checkout_content {
        margin-top: 0;
    }
    .checkout_contetn_wrapper {
        flex-direction: column-reverse;
    }
    .checkout_content_left {
        width: 100%;
    }
    .checkout_content_right {
        width: 100%;
    }
    .checkout_content_right .cart_item {
        margin: 0;
    }
    .checkout_content_right .cart_item_price {
        width: 30%;
    }
    .checkout_content_left_tabs_nav {
        margin-bottom: 20px;
        margin-top: 30px;
    }
    .firstname_field, .lastname_field, .country_field, .city_field, .address_field, .zipcode_field, .email_field, .phone_field {
        width: calc(50% - 10px);
    }
    .checkout_form_item input {
        padding: 10px;
    }
    .country_field .css-1fdsijx-ValueContainer {
        padding: 7px 0 7px 4px;
    }
    .css-1xc3v61-indicatorContainer {
        padding: 2px;
    }
}